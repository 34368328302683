const fields = [
    { key: 'index', label: "No", _style:'min-width:50px'},
    { key: 'select', label: "select", _style:'min-width:50px' },
    { key: 'nama', label: "Nama Peternak", _style:'min-width:150px;' },
    { key: 'nomor_telepon', label: "No Telepon", _style:'min-width:150px;' },
    { key: 'alamat', label: "Alamat", _style:'min-width:150px;' },
    { key: 'kecamatan', label: "Kecamatan", _style:'min-width:150px'},
    { key: 'desa', label: "Desa", _style:'min-width:150px;' },
    { key: 'alamat_kandang', label: "Alamat Kandang", _style:'min-width:150px;' },
    { key: 'nama_kelompok', label: "Kelompok", _style:'min-width:150px' },
    { key: 'jabatan', label: "jabatan", _style:'min-width:150px' },
    { key: 'dibuat_oleh', label: "Dibuat Oleh", _style:'min-width:250px' },
    { key: 'tanggal_diinput',label: "Tanggal Diinput", _style:'min-width:150px;' },
    { key: "action", label: 'Aksi', _style: 'min-width:150px'}
]


export { fields }